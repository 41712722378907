import React from 'react';
import { MDXProvider } from '@mdx-js/tag';
import { preToCodeBlock } from 'mdx-utils';
import { Code } from './components/code';

// import * as DesignSystem from "your-design-system"

// components is its own object outside of render so that the references to
// components are stable
const components = {
  pre: (preProps) => {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    }
    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  },
  // Map HTML element tag to React component
  // h1: DesignSystem.H1,
  // h2: DesignSystem.H2,
  // h3: DesignSystem.H3,
  // Or define component inline
  p: (props) => <p {...props} style={{ color: 'rebeccapurple' }} />,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
