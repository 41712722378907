// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contact-us-fr-js": () => import("./../../../src/pages/contact-us.fr.js" /* webpackChunkName: "component---src-pages-contact-us-fr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-en-js": () => import("./../../../src/pages/newsletter.en.js" /* webpackChunkName: "component---src-pages-newsletter-en-js" */),
  "component---src-pages-newsletter-fr-js": () => import("./../../../src/pages/newsletter.fr.js" /* webpackChunkName: "component---src-pages-newsletter-fr-js" */),
  "component---src-pages-product-confirmation-en-js": () => import("./../../../src/pages/product-confirmation.en.js" /* webpackChunkName: "component---src-pages-product-confirmation-en-js" */),
  "component---src-pages-product-confirmation-fr-js": () => import("./../../../src/pages/product-confirmation.fr.js" /* webpackChunkName: "component---src-pages-product-confirmation-fr-js" */),
  "component---src-pages-success-en-jsx": () => import("./../../../src/pages/success.en.jsx" /* webpackChunkName: "component---src-pages-success-en-jsx" */),
  "component---src-pages-success-fr-jsx": () => import("./../../../src/pages/success.fr.jsx" /* webpackChunkName: "component---src-pages-success-fr-jsx" */),
  "component---src-pages-support-en-js": () => import("./../../../src/pages/support.en.js" /* webpackChunkName: "component---src-pages-support-en-js" */),
  "component---src-pages-support-fr-js": () => import("./../../../src/pages/support.fr.js" /* webpackChunkName: "component---src-pages-support-fr-js" */),
  "component---src-pages-team-en-js": () => import("./../../../src/pages/team.en.js" /* webpackChunkName: "component---src-pages-team-en-js" */),
  "component---src-pages-team-fr-js": () => import("./../../../src/pages/team.fr.js" /* webpackChunkName: "component---src-pages-team-fr-js" */),
  "component---src-pages-terms-en-js": () => import("./../../../src/pages/terms.en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-fr-js": () => import("./../../../src/pages/terms.fr.js" /* webpackChunkName: "component---src-pages-terms-fr-js" */),
  "component---src-templates-blog-md-blog-en-jsx": () => import("./../../../src/templates/blog-md/blog-en.jsx" /* webpackChunkName: "component---src-templates-blog-md-blog-en-jsx" */),
  "component---src-templates-blog-md-blog-fr-jsx": () => import("./../../../src/templates/blog-md/blog-fr.jsx" /* webpackChunkName: "component---src-templates-blog-md-blog-fr-jsx" */),
  "component---src-templates-blog-md-post-en-js": () => import("./../../../src/templates/blog-md/post-en.js" /* webpackChunkName: "component---src-templates-blog-md-post-en-js" */),
  "component---src-templates-blog-md-post-fr-js": () => import("./../../../src/templates/blog-md/post-fr.js" /* webpackChunkName: "component---src-templates-blog-md-post-fr-js" */),
  "component---src-templates-blog-md-tag-en-jsx": () => import("./../../../src/templates/blog-md/tag-en.jsx" /* webpackChunkName: "component---src-templates-blog-md-tag-en-jsx" */),
  "component---src-templates-blog-md-tag-fr-jsx": () => import("./../../../src/templates/blog-md/tag-fr.jsx" /* webpackChunkName: "component---src-templates-blog-md-tag-fr-jsx" */),
  "component---src-templates-products-product-en-js": () => import("./../../../src/templates/products/product-en.js" /* webpackChunkName: "component---src-templates-products-product-en-js" */),
  "component---src-templates-products-product-fr-js": () => import("./../../../src/templates/products/product-fr.js" /* webpackChunkName: "component---src-templates-products-product-fr-js" */),
  "component---src-templates-products-products-en-jsx": () => import("./../../../src/templates/products/products-en.jsx" /* webpackChunkName: "component---src-templates-products-products-en-jsx" */),
  "component---src-templates-products-products-fr-jsx": () => import("./../../../src/templates/products/products-fr.jsx" /* webpackChunkName: "component---src-templates-products-products-fr-jsx" */),
  "component---src-templates-team-user-en-js": () => import("./../../../src/templates/team/user-en.js" /* webpackChunkName: "component---src-templates-team-user-en-js" */),
  "component---src-templates-team-user-fr-js": () => import("./../../../src/templates/team/user-fr.js" /* webpackChunkName: "component---src-templates-team-user-fr-js" */)
}

